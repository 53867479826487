import * as sdk from "microsoft-cognitiveservices-speech-sdk";

let audioConfig = null;

export function sttFromMicStream(options) {
  const key = "ed24683843294c84b4e4a761ddb2529f";
  const region = "eastasia";
  const speechConfig = sdk.SpeechConfig.fromSubscription(key, region);
  speechConfig.speechRecognitionLanguage = "zh-CN";

  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    console.log("不支持 enumerateDevices() .");
    return;
  } else {
    console.log("list devices starting...");
  }
  navigator.mediaDevices
    .enumerateDevices()
    .then(function (devices) {
      devices.forEach(function (device) {
        console.log(
          device.kind + ": " + device.label + " id = " + device.deviceId
        );
      });
    })
    .catch(function (err) {
      console.log(err.name + ": " + err.message);
    });
  if (!audioConfig) {
    audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
  }
  speechConfig.setProperty(
    sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs,
    "10000"
  );
  const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

  recognizer.recognizing = (sender, event) => {
    console.log(`RECOGNIZING: Text=${event.result.text}`);
    options.onProcessing && options.onProcessing(event.result.text);
  };

  recognizer.recognized = (s, e) => {
    if (e.result.reason == sdk.ResultReason.RecognizedSpeech) {
      console.log(`RECOGNIZED: Text=${e.result.text}`);
    } else if (e.result.reason == sdk.ResultReason.NoMatch) {
      console.log("NOMATCH: Speech could not be recognized.");
    }
    console.log(e);
    options.onStream && options.onStream(e);
  };

  recognizer.canceled = (s, e) => {
    console.log(`CANCELED: Reason=${e.reason}`);

    if (e.reason == sdk.CancellationReason.Error) {
      console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
      console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
      console.log(
        `CANCELED: Did you set the speech resource key and region values?`
      );
    }

    recognizer.stopContinuousRecognitionAsync();
  };

  recognizer.sessionStarted = (s, e) => {
    console.log("Session ID:" + e.sessionId);
  };

  recognizer.sessionStopped = (s, e) => {
    console.log("\n    Session stopped event.");
    recognizer.stopContinuousRecognitionAsync();
  };

  recognizer.startContinuousRecognitionAsync(
    () => {
      options.onStart && options.onStart();
    },
    (err) => {
      console.log("recError", err);
      options.onError && options.onError(err);
    }
  );

  return (cb, err) => {
    recognizer.stopContinuousRecognitionAsync(cb, err);
  };
}
